<template>
  <div>
    <Heard></Heard>
    <MyContent></MyContent>
    <MyFooter></MyFooter>
  </div>
</template>

<script>
import Heard from './heard'
import MyContent from './myContent'
import MyFooter from './myFooter'
export default {
  name: "index",
  components: {
    Heard,
    MyContent,
    MyFooter
  }
}
</script>

<style scoped>
</style>