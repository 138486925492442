<template>
  <div class="page" v-if="list.length!=0">
    <div class="block">
      <img :src="list[0].pic">
      <div style="cursor: pointer" class="content" @click="toPage(id)">
        <h4>热点：{{list[0].title}}</h4>
        <h5>
         {{list[0].brief}}
        </h5>
      </div>
    </div>
    <ul>
      <li style="cursor: pointer" v-for="item  in litterList" :key="item.id" @click="toPage(item.id)">
        <p>
          {{item.title}}
        </p>
        <span>{{new Date(item.time).toLocaleDateString()}}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "newInformation",
  data(){
    return{
      title:'最新资讯',
      list:[],
      litterList:[],
      id:null,
    }
  },
  mounted() {
    this.getData(this.title)
  },
  methods:{


    toPage(id){
      this.$router.push('/newsDetails?id='+id)
    },

    getData(name){
      this.list=[]
      this.$http.get('/safe/getIndexInformation',{
        params:{
          name:name
        }
      }).then((res)=>{

        let forms=res.data
        for(let i in forms){

          let pic = forms[i].pic
          if (pic != null) {
            forms[i].pic = this.$constContent.imgUrl + pic
          }
        }
        let list=[]
        for(let i=1;i<forms.length;i++){
          list.push(forms[i])
          if(list.length>5){
            break;
          }
        }

        this.litterList=list
        this.list=res.data
        this.id=res.data[0].id

      })
    },
  }
}
</script>

<style scoped>
.page {
  width: 100%;
  padding: 20px 0;
}

.block {
  width: 100%;
  height: 120px;
  margin-bottom: 10px;
  display: flex;
}

h4 {
  margin: 0 0 10px 0;
  font-size: 18px;
}

img {
  width: 30%;
  height: 120px;
}

.content {
  width: 67.5%;
  margin-left: 2.5%;
}
.content li p{
  font-size:14px;
}
.content li span{
  font-size:14px;
  color:gray;
}
h5 {
  width: 98%;
  margin: 5px 0;
  font-size: 14px;
  line-height: 24px;
  overflow: hidden;
  font-weight: 400;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
ul{
  padding: 0;
}
li {
  width: 98%;
  display: flex;
  justify-content: space-between;
  align-items: center;
    border-bottom: solid 1px #eeeeee;
}

p {
  width: 60%;
  margin: 5px 0;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

span {
  font-size: 12px;
}
</style>