<template>
  <div class="page" @mouseleave="Rotating" @mouseover="onMouseOut">
    <div @click="toLeft" class="leftButton">
      <img style="width: 50px;height: 50px;cursor: pointer" src="../../assets/image/icon/left2.png">
    </div>
    <div class="photo">
      <div class="show">
        <img style="cursor: pointer" @click="toPage(item.articleId)" :key="item.id" v-for="item in list"
             :src="item.pic"/>
      </div>
    </div>
    <div @click="toRight" class="rightButton">
      <img style="width: 50px;height: 50px; cursor: pointer" src="../../assets/image/icon/right2.png">
    </div>
    <div class="bar">
      <div  v-for="index in list.length" :key="index">
        <div class="circle">
          <div :class="{'smallCircle':index==i}"></div>
        </div>
        <div v-if="index!=list.length" class="line"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "banner",
  data() {
    return {
      list: [],
      i: 0,
      Interval: null
    }
  },
  mounted() {
    this.getBanner()
    //this.Rotating()
  },
  methods: {
    onMouseOut() {
      clearInterval(this.Interval)
      this.Interval = null
    },
    toLeft() {
      let photos = document.getElementsByClassName('show')[0]
      this.i = this.i - 1
      photos.style.left = '-' + this.i + '00%'
    },
    toRight() {
      let photos = document.getElementsByClassName('show')[0]
      this.i = this.i + 1
      if (this.i > this.list.length - 1) {
        this.i = 0
      }
      photos.style.left = '-' + this.i + '00%'
    },


    toPage(id) {
      this.$router.push('/newsDetails?id=' + id)
    },
    getBanner() {
      let ths = this;
      this.$http.get('/Rotating/getAll', {params: {type: 1}}).then((res) => {
        for (let i in res.data) {
          res.data[i].pic = ths.$constContent.imgUrl + res.data[i].pic
        }
        ths.list = res.data
        setTimeout(() => {
          ths.Rotating()
        }, 200)
      })
    },

    Rotating() {
      let ths = this;
      let photos = document.getElementsByClassName('show')[0]

      photos.style.width = ths.list.length + '00%'
      let imgs = photos.childNodes
      let imgWidth = 100 / ths.list.length

      for (let i = 0; i < imgs.length; i++) {
        imgs[i].style.width = imgWidth + '%'
      }

      ths.Interval = setInterval(() => {
        if (ths.i > ths.list.length - 1) {
          ths.i = 0
        }
        photos.style.left = '-' + ths.i + '00%'
        ths.i++;
        this.$emit('next', ths.i)
      }, 3000)
    }
  }
}
</script>

<style scoped>
.bar {
  width: 15px;
  display: flex;
  top: 279px;
  right: 264px;
  position: absolute;
  flex-wrap: wrap;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  overflow: hidden;
  border: #FFFFFF 1px solid;

  /*background-color: #FFFFFF;*/
}

.smallCircle{
  width: 8px;
  height: 8px;
  margin: 1px;
  border-radius: 8px;
  overflow: hidden;
  background-color: #FFFFFF;
}
.line{
  width: 1px;
  margin: 1px 5px;
  height: 30px;
  background-color: #FFFFFF;
}

.page {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.photo {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  /*float: left;*/
  /*position: relative;*/
  /*animation: mymove 20s infinite;*/
  /*-moz-animation: mymove 20s infinite; !* Firefox *!*/
  /*-webkit-animation: mymove 20s infinite; !* Safari and Chrome *!*/
  /*-o-animation: mymove 20s infinite;*/
}

.show {
  position: absolute;
  display: flex;
  height: 100%;
  top: 0;
  left: 0;
}

img {
  height: 100%;
}

.leftButton {
  width: 50px;
  height: 100%;
  padding: 375px 0;
  position: absolute;
  left: 0;
  z-index: 99;
}

.rightButton {
  width: 50px;
  position: absolute;
  padding: 375px 0;
  top: 0;
  left: calc(100% - 50px);
  z-index: 99;
}

/*@keyframes mymove {*/
/*  0% {transform: translate(0,0);}*/
/*  19% {transform: translate(0,0);}*/

/*  20% {transform: translate(-20%,0);}*/
/*  35% {transform: translate(-20%,0);}*/

/*  40% {transform: translate(-40%,0);}*/
/*  55% {transform: translate(-40%,0);}*/

/*  60% {transform: translate(-60%,0);}*/
/*  75% {transform: translate(-60%,0);}*/

/*  80% {transform: translate(-80%,0);}*/
/*  95% {transform: translate(-80%,0);}*/

/*  0% {transform: translate(0,0);}*/
/*}*/
</style>