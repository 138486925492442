<template>
  <div class="page">
    <h4><span class="red"></span>协会大事记 <span @click="$router.push('/deeds?name=协会事迹')">more+</span></h4>
    <div style="cursor: pointer" class="block" v-for="item in list" :key="item.id" @click="toPage(item.id)">
      <div class="date">
        <div class="day">{{item.day}}</div>
        <div class="year">{{item.year}}.{{item.month}}</div>
      </div>
      <div>
        <h5>{{item.title}}</h5>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "footerBlockR",
  data(){
    return{
      list:[]
    }
  },
  mounted() {
    this.getData()
  },
  methods:{
    toPage(id){
      this.$router.push('/newsDetails?id='+id)
    },

    getData(){
      this.$http.get('/safe/getIndexInformation', {
        params: {
          name: '协会大事记',
        }
      }).then((res) => {
        let forms=res.data

        for(let i in forms){
          let pic = forms[i].pic
          if (pic != null) {
            forms[i].pic = this.$constContent.imgUrl + pic
          }
          let time = forms[i].time;
          let date = new Date(time);
          forms[i].year=this.$constContent.dateFormat("YYYY", date)
          forms[i].month=date.getMonth()+1
          forms[i].day=this.$constContent.dateFormat('dd',date)
        }
        this.list = res.data

      })
    }
  }
}
</script>

<style scoped>
.page {
  width: 95%;
  padding: 10px 2.5%;
  border: 1px dashed #dfdfdf;
  background: #ffffff;
  border-radius: 4px;
}

.block {
  width: 100%;
  display: flex;
  align-items: center;
  border-top: 1px dashed #dfdfdf;
}

.content {
  width: 60%;
}

img {
  width: 142px;
  height: 118px;
  padding: 19px 0 19px 4px;
}
h5{
  margin: 0;
}
span {
  float: right;
  color: #999999;
  font-size: 10px;
  cursor: pointer;
  cursor: hand;
}

.red{
  width: 4px;
  height: 12px;
  margin-top: 5px;
  margin-right: 5px;
  background: red;
  float: left;
}

.date{
  width: 50px;
  height: 50px;
  margin: 10px;
  color: #ffffff;
  background: url("../../assets/image/backgroundColor.png");
  background-clip: padding-box;
  border-radius: 3px;
}
.year{
  width: 100%;
  margin: 3px auto;
  font-size: 8px;
  text-align: center;
}
.day{
  width: 100%;
  font-size: 20px;
  text-align: center;
}


p {
  font-size: 12px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

</style>