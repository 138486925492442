<template>
  <div class="timePage">
    <p>欢迎访问陕西西咸新区服务贸易协会！今天是{{year}}年{{mouth}}月{{day}}日 {{week}}</p>
    <div class="search" style="z-index: 10;">
      <input type="text" placeholder="请输入关键字">
      <img src="../../assets/image/icon/search.png">
    </div>
  </div>
</template>

<script>
export default {
  name: "MyTime",
  data() {
    return {
      year:null,
      mouth:null,
      day:null,
      week:null
    }
  },
  mounted() {
    let date = new Date()
    this.year=date.getFullYear()
    this.mouth=date.getMonth()+1
    this.day=date.getDate()
    let day=date.getDay()
    let weeks = new Array("星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六");
    this.week = weeks[day];

  }
}
</script>

<style scoped>
.timePage {
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-content: center;
}

p {
  height: 20px;
  font-size: 14px;
  line-height: 20px;
  margin: auto 0;
}

input {
  width: 142px;
  height: 32px;
  line-height: 20px;
  border: 1px solid #dfdfdf;
  padding: 7px 30px;
  margin: 0;
  border-radius: 17px;
}

.search {
  width: 204px;
  height: 48px;
  position: relative;
}

img {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 11px;
  top: 14px;
}
</style>
