<template>
  <header class="page">
    <HeardBar class="heardBar"></HeardBar>
    <Banner v-on:next="next" class="banner"></Banner>
    <HeardFooter ref="footer" class="heardFooter"></HeardFooter>
  </header>
</template>

<script>
import HeardBar from './heardBar'
import HeardFooter from './heardFooter'
import Banner from './banner'

export default {
  name: "test",
  components: {
    HeardBar,
    HeardFooter,
    Banner
  },methods:{
    next(e){
      this.$nextTick(()=>{
        this.$refs.footer.init(e)
      })
    }
  }
}
</script>

<style scoped="scoped">
.page {
  width: 100%;
  height: 800px;
  position: relative;
}

.heardBar {
  width: 80%;
  position: absolute;
  top: 0;
  height: 130px;
  z-index: 20;
}

.banner {
  height: 800px;
}

.heardFooter {
  width: 80%;
  position: absolute;
  bottom: 0;
  height: 200px;
  z-index: 10;

}
</style>