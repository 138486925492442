<template>
  <div class="page">
    <h4>重要活动通知<span @click="$router.push('/important?name=重要活动')">more+</span></h4>
    <img src="../../assets/image/zhongyaohuod.png">
    <div style="cursor: pointer" class="block" v-for="item in list" :Key="item.id" @click="toPage(item.id)">
      <h5>
        {{item.title}}
      </h5>
    </div>
  </div>
</template>

<script>
export default {
  name: "topBlockR",
  data(){
    return{
      list:[]
    }
  },
  mounted() {
    this.getData()
  },
  methods:{
    toPage(id){
      this.$router.push('/newsDetails?id='+id)
    },

    getData(){
      this.$http.get('/safe/getIndexInformation', {
        params: {
          name: '重要通知',
        }
      }).then((res) => {
        let forms=res.data

        for(let i in forms){
          let pic = forms[i].pic
          if (pic != null) {
            forms[i].pic = this.$constContent.imgUrl + pic
          }
          let time = forms[i].time;
          let date = new Date(time);
          res.data[i].time=this.$constContent.dateFormat("YYYY-mm-dd HH:MM",date)
        }
        this.list = res.data

      })
    }
  }
}
</script>

<style scoped>
.page {
  width: 95%;
  padding: 10px 2.5%;
  border: 1px dashed #dfdfdf;
  background: #f8f8f8;
  border-radius: 4px;
}

.page > img {
  width: 100%;
  height: 210px;
}

.block {
  width: calc(100% - 20px);
  padding: 5px 0;
  margin: 0 10px;
  border-bottom: #e6e6e5 1px solid;
}

span {
  float: right;
  color: #999999;
  font-size: 10px;
  cursor: pointer;
  cursor: hand;
}

h4 {
  border-left: 3px red solid;
  padding-left: 5px;
}

p {
  margin: 5px 0;
  font-size: 12px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
</style>