<template>
  <div v-if="data.length>0" class="page">
    <div class="block">
      <p style="font-size: 16px">{{data[n].title}}</p>
      <p style="font-size: 14px;margin-top: 5px">{{data[n].brief}}</p>
    </div>
    <div  class="block orangeBack">
      <div class="date">
        <div class="day">{{new Date(data[e].time).getDate()}}</div>
        <div class="year">{{new Date(data[e].time).getUTCFullYear()}}-{{new Date(data[e].time).getMonth()+1}}</div>
      </div>
      <div class="content">
        <p style="font-size: 16px">{{data[e].title}}</p>
        <p style="font-size: 14px;margin-top: 5px">{{data[e].brief}}</p>
      </div>
    </div>
    <div class="block">
      <p style="font-size: 16px">{{data[l].title}}</p>
      <p style="font-size: 14px;margin-top: 5px">{{data[l].brief}}</p>
      </div>
  </div>
</template>

<script>
export default {
  name: "heardFooter",
  data() {
    return {
      data: [],
      e: 0,
      n: 0,
      l:1,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    init(e) {
      this.e = e-1
      if (e > 1 && e < this.data.length ) {
        this.n = e - 2
        this.l = e
      } else if (e == 1) {
        this.n = this.data.length - 1
        this.l = e
      } else if (e == this.data.length) {
        this.n = e - 2
        this.l = 0
      }
    },
    getData() {
      this.$http.get('/Rotating/getAll', {params: {type: 1}}).then((res) => {
        this.data = res.data
      })
    }
  }
}
</script>

<style scoped>
.page {
  width: 80%;
  height: 200px;
  padding: 0 10%;
  background: #050001;
  opacity: 0.5;
  color: #ffffff;
}

.block {
  width: 27.3%;
  padding: 50px 3%;
  float: left;
}

.orangeBack {

  /*opacity: 0.5;*/
  display: flex;
}
.orangeBack:hover{
  background: rgba(251,216,96,0.5);
}
.orangeBack:hover .date{
  display: block;
}


.content {
  /*width: calc(100% - 100px);*/
  margin-left: 10px;
}

p {
  height: 40px;
  padding: 4px 0;
  margin: 0;
  font-size: 14px;
  line-height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.date {
  width: 98px;
  height: 98px;
  border: 1px #ffffff dashed;
  display: none;
}

.year {
  width: 80px;
  height: 20px;
  margin: 5px auto;
  font-size: 15px;
  text-align: center;
  border: 1px #ffffff dashed;
}

.day {
  width: 50px;
  height: 50px;
  margin: 10px 25px;
  font-size: 40px;
  text-align: center;
}
</style>
