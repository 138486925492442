<template>
  <div class="contentPage">
    <MyTime></MyTime>
    <div class="layout">
      <div class="left">
        <TopBlock></TopBlock>
        <NavBar></NavBar>
        <div class="bannerImg">
          <img src="../../assets/image/banner01.png">
        </div>
        <FooterBlock></FooterBlock>
      </div>
      <div class="right">
        <TopBlockR></TopBlockR>
        <div class="bannerImg">
          <img src="../../assets/image/banner_09.jpg">
        </div>
        <FooterBlockR></FooterBlockR>
      </div>
    </div>
  </div>
</template>

<script>
import MyTime from './myTime'
import TopBlock from './topBlock'
import NavBar from './navBar'
import FooterBlock from './footerBlock'
import TopBlockR from './topBlockR'
import FooterBlockR from './footerBlockR'

export default {
  name: "MyContent",
  components: {
    MyTime,
    TopBlock,
    NavBar,
    FooterBlock,
    TopBlockR,
    FooterBlockR
  }
}
</script>

<style scoped>
.contentPage {
  width: 80%;
  margin: 10px 10%;
  background: #ffffff;
}

.layout {
  width: 100%;
  display: flex;
  margin: 10px 0;
}

.layout .left {
  width: 62%;
  float: left;
  display: block;
  margin-right: 2%;
}

.layout .right {
  width: 36%;
  display: block;
  float: left;
}
.bannerImg{
  width: 100%;
  /*height: 90px;*/
  margin: 20px 0;
}
.bannerImg img{
  width: 100%;
  height: 100%;
}



</style>