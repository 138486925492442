<template>
  <div class="size">
    <router-link to="/introductionAssociation?name=协会介绍" class="block">
      <img class="white" src="../../assets/image/icon/xiehuijieshao.jpg">
      <img class="red" src="../../assets/image/icon/11.png">
      <p>协会介绍</p>
    </router-link>
    <router-link to="/synopsis?name=会员简介" class="block">
      <img class="white" src="../../assets/image/icon/huiyuandanwei.png">
      <img class="red" src="../../assets/image/icon/22.png">
      <p>会员单位</p>
    </router-link>
    <router-link to="/applicationMembership?name=入会申请" class="block">
      <img class="white" src="../../assets/image/icon/ruhuishenqin.png">
      <img class="red" src="../../assets/image/icon/33.png">
      <p>入会申请</p>
    </router-link>
    <router-link to="/mien?name=智库专家" class="block">
      <img class="white" src="../../assets/image/icon/zhikuzhuanjia.png">
      <img class="red" src="../../assets/image/icon/44.png">
      <p>智库专家</p>
    </router-link>
    <router-link to="/literature?name=专家文献" class="block">
      <img class="white" src="../../assets/image/icon/article.png">
      <img class="red" src="../../assets/image/icon/55.png">
      <p>专家文献</p>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "topBlock",
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>
.high-light {
  border: 1px double red;
}

.size {
  width: calc(90.6% - 2px);
  height: 138px;
  padding: 50px 3.8% 50px 5.6%;
  border: 1px dashed #dfdfdf;
  background: #f8f8f8;
}

.block {
  width: calc(18.4% - 2px);
  margin-right: 1.6%;
  border: 1px dashed #dfdfdf;
  /*background: #ffffff;*/
  float: left;
}
.block:hover{
  background-color: #FFFFFF;
  color: red;
  box-shadow: 3px 4px 5px #888888;
}

img {
  width: 30%;
  height: 37px;
  margin: 37px 35% 0 35%;
}
.block:hover .white{
  display: none;
}
.block:hover .red{
  display: block;
}
.white{
  display: block;
}
.red{
  display: none;
}


a {
  color: #333333;
  text-decoration: none;
}

p {
  width: 100%;
  text-align: center;
  font-size: 14px;
}
</style>
