<template>
  <div class="page">
    <div class="bar">
      <a :class="[title=='最新资讯'?'border':'']"
         @click="change('最新资讯')">最新资讯</a>
      <a :class="[title=='行业报告'?'border':'']"
         @click="change('行业报告')">行业报告</a>
      <a :class="[title=='专家文献'?'border':'']"
         @click="change('专家文献')">专家文献</a>
      <a :class="[title=='培训活动'?'border':'']"
         @click="change('培训活动')">培训活动</a>
<!--      <span>more+</span>-->
    </div>
    <div class="content">
      <new-information ref="news"></new-information>
    </div>
  </div>
</template>

<script>
import newInformation from "@/components/index/newInformation";

export default {
  name: "navBar",
  components: {newInformation},
  data(){
    return{
      title:'最新资讯',
    }
  },
  methods:{
    change(name){
     this.title=name
      this.$nextTick(()=>{
        this.$refs.news.getData(name)
      })
    }
  }
}
</script>

<style scoped>
.page {
  width: 100%;
  margin-top: 20px;
}

.bar {
  width: 100%;
  height: 30px;
  margin: auto;
}


.content {
  width: 100%;
  height: 300px;
  margin-top: 10px;
}

a {
  list-style: none;
  width: 12%;
  height: 30px;
  line-height: 30px;
  margin-right: 6px;
  float: left;
  text-decoration: none;
  color: #050001;
  cursor: pointer;
  cursor: hand;
}

a:hover {
  text-decoration: none;
  color: #050001;
}
.border{
  border-bottom: 2px solid #050001;
}
span {
  float: right;
  color: #999
}

</style>