<template>
  <div class="page">
    <h4><span class="red"></span>企业供求信息 <span @click="$router.push('/supply?name=企业供求')">more+</span></h4>
    <div style="cursor: pointer" class="block" v-for="item in list" :key="item.id" @click="toPage(item.id)">
      <img :src="url+item.companiesInformation.pic">
      <div class="content">
        <h3>{{item.name}}</h3>
        <p>
          企业类型：{{item.companiesInformation.companiesType}} &nbsp;|&nbsp;
          成立时间：{{new Date(item.companiesInformation.establishTime).toLocaleDateString()}}
        </p>
        <p>
          公司地址：{{item.companiesInformation.enrollAddr}}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footerBlock",
  data(){
    return{
      url:this.$constContent.imgUrl,
      list:[]
    }
  },
  mounted() {
    this.getData()
  },
  methods:{
    toPage(id){
      this.$router.push('/supplyDetails?id='+id+'&name=会员简介')
    },

    getData() {
        this.$http.get("/companies/webSiteGet",{
          params: {
            current: 1,
            size: 3
          }
        }).then((res)=>{
          //console.log(res.data)
          this.list = res.data.records
        })

    }
  }
}
</script>

<style scoped>
.page {
  width: 95%;
  padding: 10px 2.5%;
  border: 1px dashed #dfdfdf;
  background: #f8f8f8;
  border-radius: 4px;
}

.block {
  width: 100%;
  margin: 19px 0;
  /*background: #ffffff;*/
  display: flex;
  border-radius: 4px;
}
.block:hover{
  background-color: #FFFFFF;
}

.content {
  width: 60%;
  padding: 19px 24px;
}

img {
  width: 142px;
  /*height: 118px;*/
  padding: 19px 0 19px 4px;
}
h5{
  margin: 0;
}
span {
  float: right;
  color: #999999;
  font-size: 10px;
  cursor: pointer;
  cursor: hand;
}
.red{
  width: 4px;
  height: 12px;
  margin-top: 5px;
  margin-right: 5px;
  background: red;
  float: left;
}

p {
  font-size: 12px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

</style>